import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
width: fit-content;
text-align: center;
>h2{
  margin-bottom: 1rem;
}
`


const TitleSubtitle = ({title, subtitle}:{title:string, subtitle: string}) => {
  return (
    <Container className='sectionPadding m-auto'>
        <h2  className='h2'>{title}</h2>
        <p className='h6'  >{subtitle}</p>
    </Container>
  )
}

export default TitleSubtitle