import React from 'react'
import styled from 'styled-components'
import { BLACKGREY, Button, device, MAINCOLOR, WHITE } from '../../styles/styles'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import HeroSvg from "../../assets/svg/home/heroSvg.svg"
import MobileHeroSvg from "../../assets/svg/home/mobileHero.svg"
import useMediaQuery from '../../Hooks/useMediaQuery'

const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
background: ${WHITE};
position: relative;
padding-bottom:0;

@media ${device.laptopSmall}{
    border-radius: 1rem;
    flex-direction: row;
    align-items: revert;
    height: 45rem;
    column-gap: 10rem;
 
}
`

const TextContentStyles = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
color: ${BLACKGREY};
row-gap:1rem;    height: fit-content;



@media ${device.laptopSmall}{
    width: 55%;

}


@media ${device.tabletSmallPortrait}{
    >h1{
        font-size: 3rem;
    }
}`


const ImageContainerStyles = styled.div`
height: 100%;
z-index: 0;
width: 100%;
display: flex;
justify-content: flex-end;
align-items: flex-end;
margin-top:5rem;
>svg{
    height: 100%;
}

@media ${device.laptopSmall}{
    margin-top:0rem;

    width: 50%;
    display: block;
}

`

const BlueBackground = styled.div`
background-color: ${MAINCOLOR};
position: absolute;
top: -5rem;
left: 50%;
height: calc(100% + 5rem);
border-radius:  0 5rem;
width: 100%;
display:none;
@media ${device.laptopSmall}{
    display: block;
}
`

const TryItNowButton = styled(Button)`
display: flex;
align-items: center;
white-space: pre-wrap;
position: relative;
>p{
    font-size: 0.9rem;
    font-weight: 400;
}
`

const Hero = () => {
    const isMobile = useMediaQuery(device.mobileSmallPortrait)
    const { t } = useTranslation("common"); 
  return (
    <header className='w-full' style={{overflowX:"clip"}} >
        <Container className='sectionPadding'>
            <TextContentStyles>
                <h1 className="h1">{t("hero title")} <b>{t("5 min")}</b></h1>
                <p className='h5'>{t("hero subtitle")}</p>
                <Link href={"create"}>
                    <TryItNowButton >{t("try it now")}  <p>{t("it is free")}</p></TryItNowButton>
                    </Link>
                    {/* <p>{t("price per month")}</p> */}
            </TextContentStyles>
            <BlueBackground></BlueBackground>
            <ImageContainerStyles>
        
                {isMobile?<MobileHeroSvg/>:<HeroSvg/>}
            </ImageContainerStyles>
        </Container>
    </header>
  )
}

export default Hero

