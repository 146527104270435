import React from 'react'
import styled from 'styled-components'
import TitleSubtitle from '../components/home/TitleSubtitle'
import Hero from '../components/home/Hero.tsx'
import WebsiteExample2 from '../components/home/WebsiteExample'
import EcommerceCategories from '../components/home/EcommerceCategories'
import ImageText from '../components/home/ImageText'
import Steps from '../components/home/Steps'
import { Button } from '../styles/styles'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next';
import Head from 'next/head'
import PowerOfDataSvg from "../assets/svg/home/features/powerOfData.svg"
import AllInOneSVG from "../assets/svg/home/features/allInOne.svg"
import SimpleSolutionSvg from "../assets/svg/home/features/simpleSolution.svg"
import Link from 'next/link'
import Layout from '../components/general/Layout'
import Features from '../components/home/Features'





const Page = () => {
  const {t} = useTranslation("common")
  return (
      
     <>
    <Head>
      <title>{t("hero title")}</title>
      <meta name="description" content={t("hero subtitle")} />
    
    </Head>

      <Hero></Hero>
    <main >
    <Features></Features>
        
      <EcommerceCategories></EcommerceCategories>

        <WebsiteExample2></WebsiteExample2>
        <Steps></Steps>
        
    </main>
    </> 
  )
}



export const getStaticProps = async ({ locale }) => ({
  props: {
    ...await serverSideTranslations(locale),
  },
})


Page.getLayout = function getLayout(page) {
  return (
    <Layout>
      {page}
    </Layout>
  )
}


export default Page


