import { useTranslation } from 'next-i18next'
import React from 'react'
import styled from 'styled-components'
import { Button, device, GREYWHITE, shadow600 } from '../../styles/styles'
import TitleSubtitle from './TitleSubtitle'
import StepOneSvg from "../../assets/svg/home/steps/stepOne.svg"
import StepTwoSvg from "../../assets/svg/home/steps/stepTwo.svg"
import StepThreeSvg from "../../assets/svg/home/steps/stepThree.svg"
import Link from 'next/link'
import useMediaQuery from '../../Hooks/useMediaQuery'
const Container = styled.div`
row-gap: 2rem;
min-height: 720px;
a{
    margin: auto;
}`
 
const StepsContainer = styled.div`
row-gap: 1rem;
margin:auto;
grid-template-columns: 1fr;
grid-template-rows: 1fr 1fr 1fr;


@media ${device.laptopSmall}{
    column-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;

    
}
`

 
 
const Steps = () => {
    const {t} = useTranslation("common");
    const isMobile = useMediaQuery(device.mobileSmallPortrait)
  return (
    <section style={{background:isMobile?GREYWHITE:""}}>
        <Container className=' flex column sectionPadding'>
            <TitleSubtitle title={t("steps section title")} subtitle={t("steps section subtitle")}></TitleSubtitle>
            <StepsContainer className='grid'>
                <Step title={t("step 1 title")} subtitle={t("step one subtitle")} svg={<StepOneSvg/>}></Step>
                <Step title={t("step 2 title")} subtitle={t("step two subtitle")} svg={<StepTwoSvg/>}></Step>
                <Step title={t("step 3 title")} subtitle={t("step three subtitle")} svg={<StepThreeSvg/>}></Step>
            </StepsContainer>
            <Link href="create"><Button>{t("try it now")} →</Button></Link>
        </Container>
    </section>
  )
}

export default Steps



const StepStyle = styled.div`
display: flex;
flex-direction: column;
row-gap:0.5rem;
align-items: flex-start;
border-radius: 1rem;
background: white;
padding: 1.5rem;
max-width: 20rem;
box-shadow: ${shadow600};
`
const Step = ({svg, title, subtitle}) => {
    return(
        <StepStyle>
            {svg}

            <h3 className='h6'>{title}</h3>
            <p>{subtitle}</p>
        </StepStyle>)
    
}