import styled from 'styled-components'
import TitleSubtitle from '../../components/home/TitleSubtitle'
import ImageText from '../../components/home/ImageText'
import { Button } from '../../styles/styles'
import { useTranslation } from 'next-i18next';
import PowerOfDataSvg from "../../assets/svg/home/features/powerOfData.svg"
import AllInOneSVG from "../../assets/svg/home/features/allInOne.svg"
import SimpleSolutionSvg from "../../assets/svg/home/features/simpleSolution.svg"
import Link from 'next/link'

const FeaturesContainerStyle = styled.section`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
position: relative;

>a{
  transform: translateY(50%);
  position: absolute;
  bottom: 0;
}
`


const TitleContainer = styled.div`
margin-top: 5rem;
margin-bottom: 5rem;`
const Features = () => {
  const {t} = useTranslation("common")
  return(
    <FeaturesContainerStyle className='features section'>
       <TitleContainer>
         <TitleSubtitle title={t("A product made for local businesses")} subtitle={t("a product made for local businesses subtitle")}></TitleSubtitle>
       </TitleContainer>
      <ImageText grey title={<h2 className='h3 t600'>{t("Transform your business with the")} <b>{t("power of analytics")}</b></h2>} subtitle={t("Our platform offers an analytics tool you can use to track and grow your sales")} svg={
        <PowerOfDataSvg/>
        
      }></ImageText>
      <ImageText 
        reverse 
        subtitle={t("all in one section subtitle")} 
        title={<h2 className='h3 t600'>{t("All your orders in")} <b>{t("one place")}</b></h2>}  svg={<AllInOneSVG/>}></ImageText>
      <ImageText 
      grey
      subtitle={t("simple solution section subtitle")}
      title={<h2 className='h3 t600' >{t("a")} <b>{t("simple solution")}</b> {t("tailored for localshops needs")}</h2>} svg={
        <SimpleSolutionSvg/>
      }></ImageText>
            <Link href="create"><Button>{t("Sign up for free")}</Button></Link>
    </FeaturesContainerStyle>
  )
}

export default Features