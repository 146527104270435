import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { BLACKGREY, device, GREYWHITE, MAINCOLOR } from '../../styles/styles'
const TextContainer = styled.div`
row-gap: 1rem;
>h2{
    color: ${BLACKGREY};
    margin-bottom: 1rem;
    b{
        color: ${MAINCOLOR};
    }
}

@media ${device.laptopSmall}{
    max-width: 500px;
}
    `

const Container = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
row-gap: 2rem;
padding-top: 3rem;
padding-bottom: 3rem;

>svg{
    max-width: 100%;
    height: auto;
}



@media ${device.laptopSmall}{
    flex-direction: row;
    min-height: 720px;
    width: 100vw;
    
    >svg{
    max-width: 650px;
}
}


@media ${device.laptopSmall}{
    &.reverse{
    flex-direction: row-reverse;
}
}

`



const ImageText = ({subtitle, svg, title, reverse, grey}:{subtitle:string, svg:ReactNode, title:ReactNode, reverse?:boolean, grey?:boolean}) => {
  return (
    <section className='w-full' style={{background: grey?GREYWHITE:""}}>
        <Container  className={reverse?"reverse sectionPadding":"sectionPadding"}  >
        
            <TextContainer>
                {title}
                <div className='h6'>
                    {subtitle}
                </div>
            </TextContainer>
            {svg}
            
        </Container>
    </section>
  )
}

export default ImageText