import { useTranslation } from 'next-i18next'
import React from 'react'
import styled from 'styled-components'
import { BLACKGREY, device, GREEN, MAINCOLOR, RED } from '../../styles/styles';
import FoodSvg from "../../assets/svg/home/categories/food.svg";
import ElectronicsSvg from "../../assets/svg/home/categories/electronics.svg";
import BooksSvg from "../../assets/svg/home/categories/books.svg";
import ClothesSvg from "../../assets/svg/home/categories/clothes.svg";
import JewelrySvg from "../../assets/svg/home/categories/jewelry.svg";
import AccessoriesSvg from "../../assets/svg/home/categories/accessories.svg";
import AutoSvg from "../../assets/svg/home/categories/auto.svg";
import ToysSvg from "../../assets/svg/home/categories/toys.svg";
import ImageText from '../home/ImageText';
  

const CategoriesContainerStyle = styled.div`
display: flex;
column-gap: 2rem;
margin:auto;
>div{
  row-gap: 2rem;
}

>div:nth-child(2){
  margin-top: 5rem;
}

@media ${device.laptopSmall}{
  column-gap: 4rem;
}


`

const Instance = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap:0.5rem;
  
  >h6{
    color: ${BLACKGREY};
    margin:0;

    font-size:1rem;
  }
  >div{
    background: ${MAINCOLOR};
    padding: 0.5rem;
  border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    >svg{
      height: 2rem;
      width: 2rem;
      fill: white;
      >path{
        fill: white;
      }
    }


  }

  @media ${device.laptopSmall}{
    >h6{
    font-size:initial;
  }
    >div{
      padding: 1rem;
      >svg{
        height: 2.5rem;
      width: 2.5rem;
      }
    }
  }

 
  `
const EcommerceCategories = () => {
  const {t} = useTranslation("common")
  const [scrollValue, setScrollValue] = React.useState(0)

   



  return(
    <ImageText 
    title={<h2 className='h3 t600'>{t("sell")} <b>{t("anything")}</b> {t("you can imagine")}</h2>}
    subtitle={t("from clothes to electronics, the variety of products you can sell on our pltform is infinite so hurry up and get started now")}
    svg={
              <CategoriesContainerStyle>
          <div className='flex column afs'>
            <EcommerceCategory title={t("jewelry")} icon={<JewelrySvg/>} color={RED}/>
            <EcommerceCategory title={t("accesories")} icon={<AccessoriesSvg/>} color={GREEN}/>
            <EcommerceCategory title={t("food")} icon={<FoodSvg/>} color={"#fbdf58"}/>
            <EcommerceCategory title={t("clothes")} icon={<ClothesSvg/>} color={"#8a58f1"}/>
          </div>
          <div className='flex column afs'>
            <EcommerceCategory title={t("electronics")} icon={<ElectronicsSvg/>} color={"#ffaa1d"}/>
            <EcommerceCategory title={t("books")} icon={<BooksSvg/>} color={"#825941"}/>
            <EcommerceCategory title={t("auto and parts")} icon={<AutoSvg/>} color={RED}/>
            <EcommerceCategory title={t("toys")} icon={<ToysSvg/>} color={"#fbdf58"}/>
          </div>
        </CategoriesContainerStyle>
    }></ImageText>

  )
}



const EcommerceCategory = ({title, icon, color}) => {
return <Instance ><div>
    {icon}
</div><h3 className='h6'>{title}</h3>
</Instance>
}

export default EcommerceCategories