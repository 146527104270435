import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import useDidMountEffect from '../../Hooks/useDidMountEffect'
import Step1Svg from "../../assets/svg/home/example/step1.svg"
import Step2Svg from "../../assets/svg/home/example/step2.svg"
import Step3Svg from "../../assets/svg/home/example/step3.svg"
import Step4Svg from "../../assets/svg/home/example/step4.svg"
import Step5Svg from "../../assets/svg/home/example/step5.svg"
import Step6Svg from "../../assets/svg/home/example/step6.svg"
import Step7Svg from "../../assets/svg/home/example/step7.svg"
import Step8Svg from "../../assets/svg/home/example/step8.svg"
import { BLACKGREY, device, GREYWHITE, MAINCOLOR, shadow600 } from '../../styles/styles'
import Collapse from '../utilities/Collapse'
import { useTranslation } from 'next-i18next'
const Container = styled.div`
min-height: 40rem;
width: 100%;
display: none;
@media ${device.laptopSmall}{
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    padding: 4rem;
    padding-right:0;
    align-items: center;

}
`

const svgList = [
    (index:number)=><Step1Svg key={index}/>,
    // <Step2Svg/>,
    (index:number)=><Step3Svg key={index}/>,
    // (index:number)=><Step4Svg key={index}/>,
    (index:number)=><Step5Svg key={index}/>,
    (index:number)=><Step6Svg key={index}/>,
    (index:number)=><Step7Svg key={index}/>,
    (index:number)=><Step8Svg key={index}/>
]

const Wrapper = styled.div`
 height: 100%;
 width: 100%;
  position: relative;
  overflow-x: clip;
  :after{
    position: absolute;
    content: "";
    width: 10%;
    height: 100%;
    background: linear-gradient(to left, ${GREYWHITE} 0%, rgba(255, 255, 255, 0) 100%);
    right: 0;
    top: 0;
    pointer-events: none;
}
`

const TextContentStyles = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
color: ${BLACKGREY};
row-gap:1rem;
padding: 1.5rem;
padding-bottom:0;
@media ${device.laptopSmall}{
    width: 55%;
}
    
`

const SVGContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
border-radius: 1rem;
min-height: 30rem;
row-gap: 1rem;
width: 100%;
transform: scale(0.9);

&.active{
    transform:scale(1.1);
}
`

const WebsiteExampleContainerStyles = styled.div`
display: grid;
grid-template-columns: 90% 90% 90% 90% 90% 90%;
padding: 2rem;
overflow-x: scroll;
scroll-snap-type: x mandatory;
scroll-snap-stop: always;
column-gap: 2rem;
position: relative;
scroll-behavior: smooth;

::-webkit-scrollbar {
  display: none;
}
>div{
    scroll-snap-align: center;
}

/* Hide scrollbar for IE, Edge and Firefox */
 
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

@media ${device.laptopSmall}{
    grid-template-columns: 18rem 18rem 18rem 18rem 18rem 18rem;
    >div{
        scroll-snap-align: none;
    }
}


`



 

const WebsiteExample = () => {
    const ref = useRef<HTMLDivElement | null>(null)
    const [selectedStep, setSelectedStep] = React.useState(0)

    useDidMountEffect(()=>{
        if(ref.current){
            ref.current.scrollLeft = ((ref.current.scrollWidth/6) * selectedStep)
        }
    },[selectedStep])


  return (
    <section style={{background:GREYWHITE}} >
        <Container className='sectionPadding'>
            <TextContentStyles>
        
            <SubtitleComponent selectedStep={selectedStep} setSelectedStep={setSelectedStep}></SubtitleComponent>
        
            </TextContentStyles>
                    <Wrapper>
                        <PointsContainer >
                        {[...Array(6)].map((i, index)=>(
                            <button key={index} onClick={()=>setSelectedStep(index)} className={selectedStep === index ? "active":""}/>
                        ))}
                        </PointsContainer>
                        <WebsiteExampleContainerStyles ref={ref}>
                            {svgList.map((svg, index)=><SVGContainer key={index} className={selectedStep===index?'active transition':"transition"}>{svg(index)}</SVGContainer>)}
                            </WebsiteExampleContainerStyles>
                    </Wrapper>
        </Container>
    </section>
  )
}


const PointsContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
column-gap: 1rem;
position: absolute;
bottom: -3rem;
left: 5.5rem;
::-webkit-scrollbar {
  display: none;
}
>button{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 1px solid ${MAINCOLOR};
    background-color: transparent;
    cursor: pointer;
    &.active{
        background-color: ${MAINCOLOR};
    }

}

@media ${device.mobileSmallPortrait}{
    display: none;
}`

export default WebsiteExample




const SubtitleContainer = styled.div`
display: flex;
flex-direction: column;

height: 100% !important;
row-gap: 1rem;
::-webkit-scrollbar {
  display: none;
}
`
const StepStyle = styled.div`
display: flex;
column-gap:1rem;
align-items: flex-start;
cursor: pointer;
flex-direction:column ;


>.step-title{
display: flex;
align-items: center;

>span{
    font-size: 0.9rem;
    width: 1rem;
}
    
   
}

.collapse-container{
    margin-left: 1rem;
}

&.selected{
    >.step-title{
        *{
            color: ${MAINCOLOR};
        }
    }
}

`
const SubtitleComponent =  ({selectedStep, setSelectedStep}) => {
    const {t} = useTranslation("common")
    return(
        <SubtitleContainer>
            <Step selectedStep={selectedStep} number={0} title={t("welcome page")} subtitle={t("welcome page subtitle")} setSelectedStep={setSelectedStep}></Step>
            {/* <Step selectedStep={selectedStep} number={2} title={t("Choose language")} setSelectedStep={setSelectedStep}></Step> */}
            <Step selectedStep={selectedStep} number={1} title={t("choose product")} subtitle={t("choose product subtitle")} setSelectedStep={setSelectedStep}></Step>
            {/* <Step selectedStep={selectedStep} number={4} title={t("Choose date")} setSelectedStep={setSelectedStep}></Step> */}
            <Step selectedStep={selectedStep} number={2} title={t("choose delivery method")}  subtitle={t("choose delivery method subtitle")} setSelectedStep={setSelectedStep}></Step>
            <Step selectedStep={selectedStep} number={3} title={t("customer information")} subtitle={t("customer information subtitle")} setSelectedStep={setSelectedStep}></Step>
            <Step selectedStep={selectedStep} number={4} title={t("order overview")} subtitle={t("order overview subtitle")} setSelectedStep={setSelectedStep}></Step>
            <Step selectedStep={selectedStep} number={5} title={t("order success")} subtitle={t("order success subtitle")} setSelectedStep={setSelectedStep}></Step>
        </SubtitleContainer>
    )
}


const Step = ({number, title,subtitle,  selectedStep, setSelectedStep}) => {
    return(
        <StepStyle className={selectedStep==number?"selected transition":""} onClick={()=>setSelectedStep(number)}>
        <div className='step-title'>
            <span className={selectedStep==number? 'h5 t700':"h6 t700"}>{number}.</span>
            <h3 className={selectedStep==number? 'h5':"h6"}>{title}</h3>
        </div>

            
            <Collapse on={selectedStep==number}>
                <p>{subtitle}</p>
            </Collapse>

        
        
</StepStyle>
    )
}